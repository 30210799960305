<template>
  <div class="page">
    <div class="header">
      <img class="jaka-logo" src="../assets/jaka_logo_red.png" alt="">
      <div class="jaka-ota">上海节卡机器人平台</div>
    </div>
    <div class="main">
      <div class="login-container">
        <img class="login-img" src="../assets/loginCode.png" alt="" srcset="" v-if="loginType==1" @click="loginType=2">
        <img class="login-img" src="../assets/loginAcct.png" alt="" srcset="" v-else @click="loginType=1">
        <div class="login-tabs">
          <div class="login-title" v-if="loginType==1">账号登录</div>
          <div class="login-title" v-else>扫码登录</div>
        </div>
        <div class="login-form-container" v-show="loginType==1">
          <div class="login-input">
            <el-input placeholder="请输入您的用户名" v-model="userName" @keyup.enter="login" clearable>
              <template #prefix>
                <span class="icon iconfont icon-ota-icon_zhanghao"></span>
              </template>
            </el-input>
            <el-input placeholder="请输入您的密码" v-model="password" @keyup.enter="login" show-password>
              <template #prefix>
                <span class="icon iconfont icon-ota-password"></span>
              </template>
            </el-input>
            <el-row :gutter="20">
              <el-col :span="14">
                <el-input placeholder="请输入验证码" v-model="captcha" @keyup.enter="login" clearable>
                  <template #prefix>
                    <span class="icon iconfont icon-ota-anquan"></span>
                  </template>
                </el-input>
              </el-col>
              <el-col :span="10" @click="validCode">
                <img class="valid-img" :src="captchaImg" alt="" srcset="">
              </el-col>
            </el-row>
          </div>
          <div class="login-save">
            <span style="text-align: right;" class="icon iconfont" :class="[savePass?'icon-ota-kongjianyixuan':'icon-ota-kongjianweixuan']" @click="savePass=!savePass">记住密码</span>
          </div>
          <button class="login-button" type="button" @click="login">登录</button>
        </div>
        <div id="loginQrcode" class="login-qrcode-container" v-show="loginType==2">
          <!-- <div  class="login-qrcode">
          </div> -->
          <!-- <div class="login-text">打开微信扫描二维码登录</div> -->
        </div>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import { request } from '../utils/request';
import { getUrlParam } from '@/common/utils';

export default {
  data() {
    return {
      loginType: 1, // 1：账号登录，2：扫码登录
      userName: '',
      password: '',
      captcha: '',
      captchaImg: '',
      captchaKey: '',
      savePass: false,
    }
  },
  created() {
    window.localStorage.removeItem('token')
    this.userName = window.localStorage.getItem('userName') || ''
    this.password = window.localStorage.getItem('password') || ''
    this.weiXinLogin()
    this.validCode()
  },
  mounted() {
    this.wxQrcode()
  },
  methods: {
    validCode() {
      request('validCode', {})
      .then((data) => {
        this.captchaImg = data.captcha;
        this.captchaKey = data.key;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    login() {
      if (!this.userName) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请输入用户名",
        });
        return
      } else if (!this.password) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请输入密码",
        });
        return
      } else if (!this.captcha) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请输入验证码",
        });
        return
      }
      request('login', {
        userName: this.userName,
        password: this.password,
        captcha: this.captcha,
        key: this.captchaKey
      })
      .then((data) => {
        if (data.token) {
          window.localStorage.setItem('userName', this.userName)
          this.savePass && window.localStorage.setItem('password', this.password)
          !this.savePass && window.localStorage.removeItem('password')
          window.localStorage.setItem('token', data.token)

          window.localStorage.setItem('userInfo', JSON.stringify(data||{}))
          this.$router.replace('/')
        }
      })
      .catch((error) => {
        this.$message({
          message: error || '登录失败',
          type: 'error',
          duration: 3 * 1000
        })
      })
    },
    logout() {
      request('logout', {
        userName: 'zy',
        password: '123456',
        captcha: '123456'
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    wxQrcode() {
      const obj = window.WxLogin && new window.WxLogin({
        self_redirect:false,
        id:"loginQrcode", 
        appid: "wx9427559f5baedb70", 
        scope: "snsapi_login", 
        redirect_uri:  window.location.origin + window.location.pathname,
        state: "",
        style: "",
        href: ""
      })
      console.log(obj)
    },
    async weiXinLogin() {
      try {
        const code = getUrlParam(window.location.href, 'code')
        if (!code) return
        this.loginType = 2
        const res = await request("weiXinLogin", {code})
        console.log('weiXinLogin', res)
        if (res.token) {
          window.localStorage.setItem('token', res.token)
          window.localStorage.setItem('userInfo', JSON.stringify(res||{}))
          this.$router.replace('/')
          // window.location.href = window.location.origin + window.location.pathname
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  width: 100%;
  background: url(../assets/login-robot.png) no-repeat center / cover;
  display: flex;
  flex-direction: column;
}
.header {
  flex: 13;
  display: flex;
  position: relative;
  overflow: hidden;
}
.jaka-logo {
  margin: auto 30px;
  height: 40%;
  /* background: url(../assets/jaka_logo_red.png) no-repeat center / 100%; */
}
.jaka-ota {
  font-size: 32px;
  margin: auto 32px;
}
.main {
  flex: 73;
  /* background-color: #DC0D1E; */
  position: relative;
}
.login-container {
  max-width: 400px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 10px 0 rgb(10 10 10 / 5%),
   0 6px 20px 5px rgb(40 120 255 / 10%), 
   0 16px 24px 2px rgb(10 10 10 / 5%);
  /* float: right; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20%;
  
}

@media (max-width: 500px) {
  .login-container {
    right: 50%;
    transform: translateX(50%) translateY(-50%);
  }
}
.login-img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 66px;
  height: 66px;
}
.login-tabs {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
}
.login-title{
  /* width: 135px; */
  /* height: 35px; */
  font-size: 20px;
  color: #D80C1E;
  padding: 20px;
  cursor: pointer;
}
.login-qrcode-container {
  /* padding: 20px; */
  width: 300px;
  height: 400px;
  margin: auto;
}
.login-qrcode-container .login-qrcode {
  width: 160px;
  height: 160px;
  padding: 10px;
  margin: 40px auto;
  border: 1px solid #f4f4f4;
}
.login-text {
  height: 22px;
  line-height: 22px;
}
.login-form-container {
  padding: 20px;
}
.login-form-container .el-input {
  padding-bottom: 20px;
}
.login-form-container .login-button {
  width: 240px;
  height: 40px;
  margin-bottom: 20px;
  border: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 1px #D80C1E;
  background-color: #D80C1E;
  color: #fff;
}

.icon {
  display: block;
  color: rgb(51, 51, 51);
  font-size: 18px;
  /* height: 100px;
  line-height: 100px;
  margin: 10px auto;
  transition: font-size 0.25s linear 0s, width 0.25s linear 0s; */
}
.valid-img {
  height: 40px;
  width: 100%;
}
.login-save {
  margin-bottom: 20px;
  text-align: right;
  font-size: 14px;
}
.footer {
  flex: 9;
}
</style>